import {React, lazy, Suspense} from 'react'
import RequireAuth from './components/userAuth/requireAuth';
import PersistLogin from './components/userAuth/persistLogin';
import { Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import SkeletonFullPage from './components/skeletons/SkeletonFullPage';
import SkeletonContentPage from './components/skeletons/SkeletonContentPage';
import Missing from './pages/Missing';
import Layout from './pages/Layout';
import ErrorFallback from './components/errorHandler/ErrorFallback';
import "react-loading-skeleton/dist/skeleton.css";

const Home = lazy( () => import('./pages/Socials')); 
const Login = lazy( () => import('./pages/Login'));
const Privacy = lazy ( () => import('./pages/Privacy'));
const Terms = lazy ( () => import('./pages/Terms'));
const TermsOfService = lazy ( () => import('./pages/TermsOfService'));
const Register = lazy( () => import('./pages/Register'));
const Dashboard = lazy( () => import('./pages/Dashboard'));
const ResetPass = lazy( () => import('./pages/Resetpass'));
const InputNewPassword = lazy( () => import('./pages/Inputnewpass'));
const Messages = lazy( () => import('./pages/Messages'));
const AdminGeneral = lazy( () => import('./pages/Admin/AdminGeneral'));
const AdminStores = lazy( () => import('./pages/Admin/AdminStores'));
const AdminOrders = lazy( () => import('./pages/Admin/AdminOrders'));
const Unauthorized = lazy( () => import('./pages/Unauthorized'));

const Profile = lazy( () => import('./pages/Profile'));
const CreatepostPage = lazy( () => import('./pages/CreatepostPage'));
const PostDisplay = lazy( () => import('./pages/PostDisplay'));
const ProductProfile = lazy( () => import('./pages/ProductProfile'));
const Settings = lazy( () => import('./pages/Settings'));
const Payments = lazy( () => import('./pages/Payments'));
const CheckoutSuccess = lazy( () => import('./pages/CheckoutSuccess'));
const CheckoutCancelled = lazy( () => import('./pages/CheckoutCancelled'));

const StoreSettings = lazy( () => import('./pages/Settings'));
const StoreRegister = lazy( () => import('./pages/StoreRegister'));
const StoreProfile = lazy( () => import('./pages/StoreProfile'));
const StoreDashboard = lazy( () => import('./pages/StoreDashboard'));
const StoreBackend = lazy( () => import('./pages/Storebackend'));


const ROLES = {
  'User': 2001,
  'Manager': 3780,
  'Admin': 5150
}


function App() {

  const navigate = useNavigate()

  return (
    
    <Routes>
      
      <Route path="/" element={<Layout />}>

        <Route element={<PersistLogin />}> 
              
              <Route path="privacy" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonContentPage />}>
                <Privacy />
                </Suspense>
              </ErrorBoundary>} />

              <Route path="terms" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonContentPage />}>
                <Terms />
                </Suspense>
              </ErrorBoundary>} />

              <Route path="termsofservice" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonContentPage />}>
                <TermsOfService />
                </Suspense>
              </ErrorBoundary>} />

              <Route path="resetpassword" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonContentPage />}>
                <ResetPass />
                </Suspense>
              </ErrorBoundary>} />
              
              <Route path="inputnewpassword" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonContentPage />}>
                <InputNewPassword />
                </Suspense>
              </ErrorBoundary>} />

              <Route path="register" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonContentPage />}>
                <Register />
                </Suspense>
              </ErrorBoundary>} />

              <Route path="storeregister" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonContentPage />}>
                <StoreRegister />
                </Suspense>
              </ErrorBoundary>} />

              <Route path="unauthorized" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonContentPage />}>
                <Unauthorized />
                </Suspense>
              </ErrorBoundary>} />

              <Route path="home" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonContentPage />}>
                <Home />
                </Suspense>
              </ErrorBoundary>} />
              
              <Route path="login" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonContentPage />}>
                <Login />
                </Suspense>
              </ErrorBoundary>} />

              <Route path="" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonFullPage />}>
                <Navigate to='/shop' />
                </Suspense>
              </ErrorBoundary>} />
              
              <Route path="social" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonFullPage />}>
                <Dashboard />
                </Suspense>
              </ErrorBoundary>} />

              <Route path="shop" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonFullPage />}>
                <StoreDashboard />
                </Suspense>
              </ErrorBoundary>} />

              <Route path="createpost" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonFullPage />}>
                <CreatepostPage />
                </Suspense>
              </ErrorBoundary>} />

              <Route path="displaypost/:postId" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonFullPage />}>
                <PostDisplay />
                </Suspense>
              </ErrorBoundary>} />

              <Route path="profile/store/:storename/" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonFullPage />}>
                <StoreProfile />
                </Suspense>
              </ErrorBoundary>} />

              <Route path="profile/user/:username/" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonFullPage />}>
                <Profile />
                </Suspense>
              </ErrorBoundary>} />

              <Route path="profile/product/:productId" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonFullPage />}>
                <ProductProfile />
                </Suspense>
              </ErrorBoundary>} />

              <Route path="checkout-success" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonFullPage />}>
                <CheckoutSuccess />
                </Suspense>
              </ErrorBoundary>} />

              <Route path="checkout-cancelled" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonFullPage />}>
                <CheckoutCancelled />
                </Suspense>
              </ErrorBoundary>} />


              <Route path="settings" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonFullPage />}>
                <Settings />
                </Suspense>
              </ErrorBoundary>} />

          </Route>

          {/* we want to protect these routes */}

              <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Manager, ROLES.Admin]} />}>  

                <Route path="messages" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/messages')}
              >
                <Suspense fallback={<SkeletonFullPage />}>
                <Messages />
                </Suspense>
              </ErrorBoundary>} />

                <Route path="payments" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/payments')}
              >
                <Suspense fallback={<SkeletonFullPage />}>
                <Payments />
                </Suspense>
              </ErrorBoundary>} />

            </Route>

            <Route element={<RequireAuth allowedRoles={[ROLES.Manager]} />}>
              
              <Route path="storebackend" element={<ErrorBoundary
              FallbackComponent={ErrorFallback}
              onReset={() => navigate('/')}
            >
              <Suspense fallback={<SkeletonFullPage />}>
              <StoreBackend />
              </Suspense>
            </ErrorBoundary>} />
              
              <Route path="storesettings" element={<ErrorBoundary
              FallbackComponent={ErrorFallback}
              onReset={() => navigate('/')}
            >
              <Suspense fallback={<SkeletonFullPage />}>
              <StoreSettings />
              </Suspense>
            </ErrorBoundary>} />

            </Route>

              <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>

                <Route path="admingeneral" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonFullPage />}>
                <AdminGeneral />
                </Suspense>
              </ErrorBoundary>} />
                
                <Route path="adminallorders" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonFullPage />}>
                <AdminOrders />
                </Suspense>
              </ErrorBoundary>} />
                
                <Route path="adminstores" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonFullPage />}>
                <AdminStores />
                </Suspense>
              </ErrorBoundary>} />
                
                <Route path="adminuserorders" element={<ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => navigate('/')}
              >
                <Suspense fallback={<SkeletonFullPage />}>
                <AdminOrders />
                </Suspense>
              </ErrorBoundary>} />

              </Route>

        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;