import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonFullPage = () => {
    return (
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} 
      className="flex flex-col gap-y-2 w-full justify-center 
        items-center md:px-5 lg:px-10 pt-2">
        
        <div className="flex flex-col justify-center items-center h-[12vh] sm:h-[13vh] 
            md:h-[15vh] w-full gap-y-2">
            <Skeleton height={45} width={500} />
            <Skeleton height={40} width={500}/>
        </div>
        <div className="flex flex-row justify-center items-center w-full gap-x-24">
            <Skeleton height={30} width={150} />
            <Skeleton height={30} width={80}/>
        </div>
  
        <div  style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} 
        className="pt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 
            py-4 pb-12 gap-x-2 sm:gap-x-4 md:gap-x-6 overflow-auto z-[0] w-full 
            justify-center">
            
            {Array(6)
            .fill()
            .map((item, index) => (

            <div className="flex py-4 gap-y-1 flex-col rounded-lg col-span-1" key={index}>

                <Skeleton height={240} width={240}/>
                
                <div className="flex flex-row justify-center items-center w-full h-full">
                    <Skeleton circle={true} height={25} width={25} /> &nbsp;
                    <Skeleton height={20} width={90} />
                </div>

                <div className="flex items-center justify-center">
                    <Skeleton height={40} width={220} />
                </div>

                <div className="flex items-center justify-center">
                    <Skeleton height={20} width={220} />
                </div>
            </div>
            ))}
        </div>
      </div>
    );
  };

  export default SkeletonFullPage;