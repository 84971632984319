import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonContentPage = () => {
    
    return (
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} 
      className="flex flex-col gap-y-2 w-full justify-center 
        items-center md:px-5 lg:px-10 pt-2">
        
        <div className="flex flex-col justify-center items-center h-[12vh] sm:h-[13vh] 
            md:h-[15vh] w-full gap-y-2">
            <Skeleton height={150} width={300} />
        </div>
  
        <div  style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} 
        className="pt-4 w-full flex flex-col">
            
            {Array(3)
            .fill()
            .map((item, index) => (

            <div className="flex py-4 gap-y-1 flex-col rounded-lg col-span-1" key={index}>

                <Skeleton height={30} width={300}/>
                
                <div className="flex flex-col gap-y-2">
                    <Skeleton height={20} width={300} />
                    <Skeleton height={20} width={300} />
                    <Skeleton height={20} width={300} />
                    <Skeleton height={20} width={300} />
                    <Skeleton height={20} width={300} />
                    <Skeleton height={20} width={300} />
                </div>
            </div>
            ))}
        </div>
      </div>
    );
  };

  export default SkeletonContentPage;